import { ReactComponent as Code } from "assets/icons/code.svg";
import { ReactComponent as Category } from "assets/icons/category.svg";
import { ReactComponent as Chat } from "assets/icons/chat.svg";
import { ReactComponent as Compass } from "assets/icons/compass.svg";
import { ReactComponent as Wallet } from "assets/icons/wallet.svg";
import { ReactComponent as Logout } from "assets/icons/log-out.svg";
import { ReactComponent as DotHorizontal } from "assets/icons/dot-horizontal.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Briefcase } from "assets/icons/briefcase.svg";
import { ReactComponent as Collection } from "assets/icons/collection.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Play } from "assets/icons/play.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as List } from "assets/icons/list.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { ReactComponent as Cross } from "assets/icons/cross.svg";
import { ReactComponent as Warning } from "assets/icons/warning.svg";
import { ReactComponent as Pen } from "assets/icons/pen.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";

const Icons = {
	Code,
	Category,
	Chat,
	Compass,
	Wallet,
	Logout,
	DotHorizontal,
	Cog,
	Users,
	Briefcase,
	Collection,
	Plus,
	Play,
	Search,
	List,
	ArrowUp,
	Trash,
	Check,
	Cross,
	Warning,
	Pen,
	Close,
};

export default Icons;
