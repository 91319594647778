export const users = [
	{
		name: "Shakil Ahmed",
		email: "shakil.hv@gmail.com",
		avatar: "https://avatars.githubusercontent.com/u/24731446",
	},
	{
		name: "Alen Shuvo",
		email: "allen.shuvo@gmail.com",
		avatar:
			"https://www.citrix.com/blogs/wp-content/upload/2018/03/slack_compressed-e1521621363404-360x360.jpg",
	},
	{
		name: "Rio Jeniro",
		email: "rio@gmail.com",
		avatar:
			"https://carlosaaron.com/assets/pages/media/profile/profile_user.jpg",
	},
	{
		name: "Shakil Ahmed",
		email: "shakil.hv2@gmail.com",
		avatar: "https://avatars.githubusercontent.com/u/24731446",
	},
	{
		name: "Alen Shuvo",
		email: "allen2.shuvo@gmail.com",
		avatar:
			"https://www.citrix.com/blogs/wp-content/upload/2018/03/slack_compressed-e1521621363404-360x360.jpg",
	},
	{
		name: "Rio Jeniro",
		email: "rio2@gmail.com",
		avatar:
			"https://carlosaaron.com/assets/pages/media/profile/profile_user.jpg",
	},
];
