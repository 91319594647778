import Vertical from "./Vertical";
import Horizontal from "./Horizontal";
import Sidebar from "./Sidebar";

const navbar = {
	Vertical,
	Horizontal,
	Sidebar,
};

export default navbar;
