export const LOGIN_CLASSES =
	"w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border-transparent focus:border-blue-500 focus:bg-white focus:outline-none text-[14px] placeholder:text-sm placeholder:opacity-60 pl-[17px]";

export const PALETTE = [
	"#83C7FF",
	"#3D7DFF",
	"#5C5CE5",
	"#A29BFE",
	"#483EBD",
	"#7F9CF5",
	"#E54C4C",
	"#FF5A89",
	"#FFA0A0",
	"#B82F67",
	"#F97850",
	"#FFB8A1",
	"#F4C222",
	"#0F936E",
	"#64B9A1",
	"#33CCCC",
	"#109D9D",
	"#9AD8D8",
	"#919DAF",
	"#415372",
	"#001133",
	"#83C7FF",
	"#3D7DFF",
	"#5C5CE5",
	"#A29BFE",
	"#483EBD",
	"#7F9CF5",
	"#E54C4C",
	"#FF5A89",
	"#FFA0A0",
];
